import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"

import Layout from "../../components/layout"
import Slider from "../../components/slider"

const Hirek = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      q: allMarkdownRemark(
        limit: 20
        filter: { fields: { category: { eq: "hirek" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
              category
            }
            excerpt
            timeToRead
            frontmatter {
              title
              description
              date
              author
            }
          }
        }
      }
    }
  `)

  const title = "Hírek"
  const subtitle = "Friss hírek és események"

  return (
    <Layout location={location} title={title} description={subtitle}>

      <Slider title={title} subtitle={subtitle}/>

      <Container>
        {data.q.edges.map(({ node }) => {
          return (
            <article>
              <header>
                <h5>
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </h5>
              </header>
              <section>
                <small>Frissítve: {node.frontmatter.date}</small>
                <p>{node.frontmatter.description}</p>
              </section>
              <hr/>
            </article>
          )
        })}
      </Container>
    </Layout>
  )
}

export default Hirek
